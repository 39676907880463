import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type OperableCompanyBasesState = {
  isFetching: boolean;
  isFetched: boolean;
  errorMessages: string[];
  companyBases: CompanyBaseInformationCoreModel[];
  selectedCompanyBaseId?: number;
};

const initialState: OperableCompanyBasesState = {
  isFetching: false,
  isFetched: false,
  errorMessages: [],
  companyBases: [],
};

type FetchOperableCompanyBasesSuccessAction = PayloadAction<{
  companyBases: CompanyBaseInformationCoreModel[];
  selectedCompanyBaseId: number;
}>;

type FetchOperableCompanyBasesFailedAction = PayloadAction<{
  messages: string[];
}>;

type ChangeCompanyBaseAction = PayloadAction<{
  companyBaseId: number;
}>;

export const operableCompanyBases = createSlice({
  name: 'operableCompanyBases',
  initialState,
  reducers: {
    fetch: (state) => ({
      ...state,
      isFetching: true,
      errorMessages: [],
    }),
    fetchSuccess: (state, action: FetchOperableCompanyBasesSuccessAction) => ({
      ...state,
      isFetching: false,
      isFetched: true,
      companyBases: action.payload.companyBases,
      selectedCompanyBaseId: action.payload.selectedCompanyBaseId,
      errorMessages: [],
    }),
    fetchFailed: (state, action: FetchOperableCompanyBasesFailedAction) => ({
      ...state,
      isFetching: false,
      isFetched: true,
      errorMessages: action.payload.messages,
    }),
    changeCompanyBase: (state, action: ChangeCompanyBaseAction) => ({
      ...state,
      selectedCompanyBaseId: action.payload.companyBaseId,
    }),
    init: () => initialState,
  },
});
