import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { operableCompanyBases } from './operableCompanyBases';
import { router } from './router';

type MembersState = {
  isFetching: boolean;
  isFetched: boolean;
  errorMessages: string[];
  members: MemberModel[];
  totalCount: {
    all: number;
    available: number;
    verifying: number;
    removed: number;
  };
};

const initialState: MembersState = {
  isFetching: true,
  isFetched: false,
  errorMessages: [],
  members: [],
  totalCount: {
    all: 0,
    available: 0,
    verifying: 0,
    removed: 0,
  },
};

type FetchMembersSuccessAction = PayloadAction<MembersInformationModel>;

type FetchMembersFailedAction = PayloadAction<{
  messages: string[];
}>;

export const members = createSlice({
  name: 'members',
  initialState,
  reducers: {
    fetch: (state) => ({
      ...state,
      isFetching: true,
      isFetched: false,
      errorMessages: [],
    }),
    fetchSuccess: (state, action: FetchMembersSuccessAction) => ({
      ...state,
      isFetching: false,
      isFetched: true,
      members: action.payload.members,
      totalCount: action.payload.totalCount,
      errorMessages: [],
    }),
    fetchFailed: (state, action: FetchMembersFailedAction) => ({
      ...state,
      isFetching: false,
      isFetched: false,
      errorMessages: action.payload.messages,
    }),
    init: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(router.actions.pageChange, () => initialState);
    builder.addCase(
      operableCompanyBases.actions.changeCompanyBase,
      () => initialState,
    );
  },
});
