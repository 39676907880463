import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { router } from './router';

type UpdatePasswordFormState = {
  isError: boolean;
  isLoading: boolean;
  errorMessages: string[];
  succeeded: boolean;
};

const initialState: UpdatePasswordFormState = {
  isError: false,
  isLoading: false,
  errorMessages: [],
  succeeded: false,
};

type UpdatePasswordFailedAction = PayloadAction<{
  messages: string[];
}>;

export const updatePasswordForm = createSlice({
  name: 'updatePasswordForm',
  initialState,
  reducers: {
    update: () => ({
      isError: false,
      isLoading: true,
      errorMessages: [],
      succeeded: false,
    }),
    updateSuccess: () => ({
      isError: false,
      isLoading: false,
      errorMessages: [],
      succeeded: true,
    }),
    updateFailed: (_, action: UpdatePasswordFailedAction) => ({
      isError: true,
      isLoading: false,
      errorMessages: action.payload.messages,
      succeeded: false,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(router.actions.pageChange, () => initialState);
  },
});
