import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { router } from './router';

type ResetPasswordFormState = {
  isLoading: boolean;
  errorMessages: string[];
  done: boolean;
};

const initialState: ResetPasswordFormState = {
  isLoading: false,
  errorMessages: [],
  done: false,
};

type ResetPasswordFailedAction = PayloadAction<{
  messages: string[];
}>;

export const resetPasswordForm = createSlice({
  name: 'resetPasswordForm',
  initialState,
  reducers: {
    reset: () => ({
      isLoading: true,
      errorMessages: [],
      done: false,
    }),
    resetSuccess: () => ({
      isLoading: false,
      errorMessages: [],
      done: true,
    }),
    resetFailed: (_, action: ResetPasswordFailedAction) => ({
      isLoading: false,
      errorMessages: action.payload.messages,
      done: false,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(router.actions.pageChange, () => initialState);
  },
});
