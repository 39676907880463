import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type OrderHistoryState = {
  isFetching: boolean;
  orderHistory?: CompanyBaseOrderInformationModel;
  errorMessages: string[];
};

const initialState: OrderHistoryState = {
  isFetching: true,
  errorMessages: [],
};

type FetchOrderHistorySuccessAction = PayloadAction<{
  orderHistory: CompanyBaseOrderInformationModel;
}>;

type FetchOrderHistoryFailedAction = PayloadAction<{
  messages: string[];
}>;

export const orderHistory = createSlice({
  name: 'orderHistory',
  initialState,
  reducers: {
    fetch: (state) => ({
      ...state,
      isFetching: true,
      errorMessages: [],
    }),
    fetchSuccess: (state, action: FetchOrderHistorySuccessAction) => ({
      ...state,
      isFetching: false,
      orderHistory: action.payload.orderHistory,
      errorMessages: [],
    }),
    fetchFailed: (state, action: FetchOrderHistoryFailedAction) => ({
      ...state,
      isFetching: false,
      errorMessages: action.payload.messages,
    }),
  },
});
