import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { router } from './router';

type WorkspaceState = {
  isFetching: boolean;
  workspace?: WorkspaceInformationModel;
};

const initialState: WorkspaceState = {
  isFetching: true,
};

type FetchWorkspaceSuccessAction = PayloadAction<{
  workspace: WorkspaceInformationModel;
}>;

type FetchWorkspaceFailedAction = PayloadAction<{
  messages: string[];
}>;

export const workspace = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    fetch: (state) => ({
      ...state,
      isFetching: true,
    }),
    fetchSuccess: (state, action: FetchWorkspaceSuccessAction) => ({
      ...state,
      isFetching: false,
      workspace: action.payload.workspace,
    }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchFailed: (state, _: FetchWorkspaceFailedAction) => ({
      ...state,
      isFetching: false,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(router.actions.pageChange, () => initialState);
  },
});
