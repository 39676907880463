import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type MemberInvitationsState = {
  isSubmitting: boolean;
  isSucceeded: boolean;
  errorMessages: string[];
};

const initialState: MemberInvitationsState = {
  isSubmitting: true,
  isSucceeded: false,
  errorMessages: [],
};

type SubmitMembersInvitationsFailedAction = PayloadAction<{
  messages: string[];
}>;

export const memberInvitations = createSlice({
  name: 'memberInvitations',
  initialState,
  reducers: {
    submit: (state) => ({
      ...state,
      isSubmitting: true,
      isSucceeded: false,
      errorMessages: [],
    }),
    submitSuccess: (state) => ({
      ...state,
      isSubmitting: false,
      isSucceeded: true,
      errorMessages: [],
    }),
    submitFailed: (state, action: SubmitMembersInvitationsFailedAction) => ({
      ...state,
      isSubmitting: false,
      isSucceeded: false,
      errorMessages: action.payload.messages,
    }),
    init: () => initialState,
  },
});
