import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { modal } from './modal';

type MemberRegistrationsState = {
  isSubmitting: boolean;
  isSucceeded: boolean;
  errorMessages: string[];
  registeredCount: number;
};

const initialState: MemberRegistrationsState = {
  isSubmitting: false,
  isSucceeded: false,
  errorMessages: [],
  registeredCount: 0,
};

type SubmitMembersRegistrationsSuccessAction = PayloadAction<{
  registeredCount: number;
}>;

type SubmitMembersRegistrationsFailedAction = PayloadAction<{
  messages: string[];
}>;

export const memberRegistrations = createSlice({
  name: 'memberRegistrations',
  initialState,
  reducers: {
    submit: (state) => ({
      ...state,
      isSubmitting: true,
      isSucceeded: false,
      errorMessages: [],
      registeredCount: 0,
    }),
    submitSuccess: (
      state,
      action: SubmitMembersRegistrationsSuccessAction,
    ) => ({
      ...state,
      isSubmitting: false,
      isSucceeded: true,
      errorMessages: [],
      registeredCount: action.payload.registeredCount,
    }),
    submitFailed: (state, action: SubmitMembersRegistrationsFailedAction) => ({
      ...state,
      isSubmitting: false,
      isSucceeded: false,
      errorMessages: action.payload.messages,
      registeredCount: 0,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(modal.actions.close, () => initialState);
  },
});
