import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type OrdersState = {
  dateListIsLoading: boolean;
  dateListErrorMessages: string[];
  dateList: { label: string; value: string }[];
  isFetching: boolean;
  orders: OrderListModel[];
  errorMessages: string[];
  totalCount: {
    all: number;
    ordered: number;
    cancelled: number;
  };
};

const initialState: OrdersState = {
  dateListIsLoading: false,
  dateListErrorMessages: [],
  dateList: [],
  isFetching: false,
  orders: [],
  errorMessages: [],
  totalCount: {
    all: 0,
    ordered: 0,
    cancelled: 0,
  },
};

type LoadDateListSuccessAction = PayloadAction<{
  dateList: { label: string; value: string }[];
}>;

type LoadDateListFailedAction = PayloadAction<{
  messages: string[];
}>;

type FetchOrdersSuccessAction = PayloadAction<{
  orders: OrderListModel[];
  totalCount: {
    all: number;
    ordered: number;
    cancelled: number;
  };
}>;

type FetchOrdersFailedAction = PayloadAction<{
  messages: string[];
}>;

export const orders = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    dateListLoadStart: (state) => ({
      ...state,
      dateListIsLoading: true,
      dateListErrorMessages: [],
    }),
    dateListLoadSuccess: (state, action: LoadDateListSuccessAction) => ({
      ...state,
      dateList: action.payload.dateList,
      dateListIsLoading: false,
      dateListErrorMessages: [],
    }),
    dateListLoadFailed: (state, action: LoadDateListFailedAction) => ({
      ...state,
      dateListIsLoading: false,
      dateListErrorMessages: action.payload.messages,
    }),
    fetchOrdersStart: (state) => ({
      ...state,
      isFetching: true,
      errorMessages: [],
    }),
    fetchOrdersSuccess: (state, action: FetchOrdersSuccessAction) => ({
      ...state,
      isFetching: false,
      errorMessages: [],
      orders: action.payload.orders,
      totalCount: action.payload.totalCount,
    }),
    fetchOrdersFailed: (state, action: FetchOrdersFailedAction) => ({
      ...state,
      isFetching: false,
      errorMessages: action.payload.messages,
    }),
  },
});
