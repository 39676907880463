import { Middleware, Action } from '../types';
import { router } from '../modules/router';

type RouterActionType = (typeof router)['actions'];

const isRouterUpdateAction = (
  action: Action,
): action is ReturnType<RouterActionType[keyof RouterActionType]> =>
  action.type === router.actions.update.type;

const checkPageChange: Middleware =
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    if (!isRouterUpdateAction(action)) {
      return next(action);
    }

    const state = getState();
    const currentUrl = state.router.url;
    const newUrl = action.payload.url;

    if (currentUrl === newUrl) {
      return next(action);
    }

    dispatch(router.actions.pageChange({ url: newUrl }));
    return next(action);
  };

export default checkPageChange;
