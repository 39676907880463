import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { modal } from './modal';

type MembersStatusState = {
  isUpdated: boolean;
  errorMessages: string[];
};

const initialState: MembersStatusState = {
  isUpdated: false,
  errorMessages: [],
};

type UpdateMembersRegistrationsFailedAction = PayloadAction<{
  messages: string[];
}>;

export const membersStatus = createSlice({
  name: 'membersStatus',
  initialState,
  reducers: {
    update: (state) => ({
      ...state,
      isUpdated: false,
      errorMessages: [],
    }),
    updateSuccess: (state) => ({
      ...state,
      isUpdated: true,
      errorMessages: [],
    }),
    updateFailed: (state, action: UpdateMembersRegistrationsFailedAction) => ({
      ...state,
      isUpdated: false,
      errorMessages: action.payload.messages,
    }),
    init: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(modal.actions.close, () => initialState);
  },
});
