import { StyledEngineProvider } from '@mui/styled-engine-sc';
import { ThemeProvider as MuiThemeProvider } from '@mui/styles';
import { ThemeProvider as StafesThemeProvider } from '@stafes/gochikuru-now-ui/lib/styles/ThemeProvider';
import * as React from 'react';
import { ThemeProvider as SCThemeProvider } from 'styled-components';

import { GlobalStyle } from './GlobalStyle';
import { theme } from './theme';

const AppStylesProvider: React.FC = ({ children }) => (
  <StyledEngineProvider injectFirst>
    <StafesThemeProvider>
      <SCThemeProvider theme={theme}>
        <MuiThemeProvider theme={theme}>
          <>
            <GlobalStyle />
            {children}
          </>
        </MuiThemeProvider>
      </SCThemeProvider>
    </StafesThemeProvider>
  </StyledEngineProvider>
);

export { AppStylesProvider };
