import { combineReducers } from '@reduxjs/toolkit';

import { downloadOrders } from './modules/downloadOrders';
import { loginForm } from './modules/loginForm';
import { logoutForm } from './modules/logoutForm';
import { memberInvitations } from './modules/memberInvitations';
import { memberRegistrations } from './modules/memberRegistrations';
import { members } from './modules/members';
import { membersStatus } from './modules/membersStatus';
import { modal } from './modules/modal';
import { news } from './modules/news';
import { operableCompanyBases } from './modules/operableCompanyBases';
import { orderDetail } from './modules/orderDetail';
import { orderHistory } from './modules/orderHistory';
import { orders } from './modules/orders';
import { resetPasswordForm } from './modules/resetPasswordForm';
import { resetPasswordRequestForm } from './modules/resetPasswordRequestForm';
import { router } from './modules/router';
import { session } from './modules/session';
import { setPasswordForm } from './modules/setPasswordForm';
import { setPasswordToken } from './modules/setPasswordToken';
import { shortUrl } from './modules/shortUrl';
import { slackAppInstallation } from './modules/slackAppInstallation';
import { slackMessageForm } from './modules/slackMessageForm';
import { updateCompanyBase } from './modules/updateCompanyBase';
import { updatePasswordForm } from './modules/updatePasswordForm';
import { workspace } from './modules/workspace';

export const reducer = combineReducers({
  downloadOrders: downloadOrders.reducer,
  loginForm: loginForm.reducer,
  logoutForm: logoutForm.reducer,
  memberInvitations: memberInvitations.reducer,
  memberRegistrations: memberRegistrations.reducer,
  members: members.reducer,
  membersStatus: membersStatus.reducer,
  modal: modal.reducer,
  news: news.reducer,
  operableCompanyBases: operableCompanyBases.reducer,
  orderDetail: orderDetail.reducer,
  orderHistory: orderHistory.reducer,
  orders: orders.reducer,
  resetPasswordForm: resetPasswordForm.reducer,
  resetPasswordRequestForm: resetPasswordRequestForm.reducer,
  router: router.reducer,
  session: session.reducer,
  setPasswordForm: setPasswordForm.reducer,
  setPasswordToken: setPasswordToken.reducer,
  shortUrl: shortUrl.reducer,
  slackAppInstallation: slackAppInstallation.reducer,
  slackMessageForm: slackMessageForm.reducer,
  updateCompanyBase: updateCompanyBase.reducer,
  updatePasswordForm: updatePasswordForm.reducer,
  workspace: workspace.reducer,
});

export type Reducer = typeof reducer;
