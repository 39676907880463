// Theme for styled-components
import { createTheme } from '@mui/material/styles';
import { small, middle, large } from './breakpoints';

const MuiDefaultTheme = createTheme();

export const theme = {
  spacing: (space: number) => 4 * space, // marginの基本単位
  variables: {
    navWidth: '200px', // サイドバーの幅
    headerHeight: '57px', // ヘッダーの高さ
    footerHeight: '62px', // フッターの高さ
  },
  font: {
    fontFamily:
      "'Hiragino Kaku Gothic ProN', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', YuGothic, 'Yu Gothic Medium', 'HirIagino Sans', Meiryo, sans-serif",
  },
  base: {
    backgroundColor: '#F6F6F6',
  },
  colors: {
    red: '#E7465A',
    blue: '#4A90E2',
    yellow: '#FBC600',
    white: '#FFFFFF',
    whiteGray: '#C4C4C4',
    lightGray: '#EEEEEE',
    black2: '#333333', // 文章だが、注意書き等に利用するもの
    black3: '#111111', // 表のヘッダ用
  },
  border: {
    normal: '#C4C4C4',
    error: '#E7465A',
  },
  nav: {
    colors: {
      activeBackground: '#999999',
      activeText: '#FFFFFF',
    },
  },
  breakPoints: {
    small,
    middle,
    large,
  },
  zIndex: {
    ...MuiDefaultTheme.zIndex,
    nav: 1,
  },
  palette: {
    ...MuiDefaultTheme.palette,
    primary: {
      light: '#fff94f',
      main: '#fbc600',
      dark: '#c39600',
      contrastText: '#000000',
    },
    secondary: {
      main: '#4A90E2',
    },
    error: {
      main: '#E7465A',
    },
  },
  shape: MuiDefaultTheme.shape,
  transitions: MuiDefaultTheme.transitions,
  typography: MuiDefaultTheme.typography,
  mixins: MuiDefaultTheme.mixins,
  shadows: MuiDefaultTheme.shadows,
  breakpoints: MuiDefaultTheme.breakpoints,
};
