import { configureStore, DeepPartial } from '@reduxjs/toolkit';
import { State, Action } from './types';
import { reducer } from './reducer';
import { middleware } from './middlewares';

export const getStore = (preloadedState?: DeepPartial<State>) =>
  configureStore<State, Action, typeof middleware>({
    reducer,
    middleware,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    preloadedState,
  });
