import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type DownloadOrdersState = {
  isRequesting: boolean;
  isSucceeded: boolean;
  errorMessages: string[];
};

const initialState: DownloadOrdersState = {
  isRequesting: false,
  isSucceeded: false,
  errorMessages: [],
};

type DownloadOrdersFailedAction = PayloadAction<{
  messages: string[];
}>;

export const downloadOrders = createSlice({
  name: 'downloadOrders',
  initialState,
  reducers: {
    init: () => initialState,
    start: (state) => ({
      ...state,
      isRequesting: true,
      isSucceeded: false,
      errorMessages: [],
    }),
    success: (state) => ({
      ...state,
      isRequesting: false,
      isSucceeded: true,
      errorMessages: [],
    }),
    failed: (state, action: DownloadOrdersFailedAction) => ({
      ...state,
      isRequesting: false,
      isSucceeded: false,
      errorMessages: action.payload.messages,
    }),
  },
});
