import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { router } from './router';

type SlackMessageFormState = {
  isError: boolean;
  isLoading: boolean;
  errorMessages: string[];
  posted: boolean;
};

const initialState: SlackMessageFormState = {
  isError: false,
  isLoading: false,
  errorMessages: [],
  posted: false,
};

type PostSlackMessageFailedAction = PayloadAction<{
  messages: string[];
}>;

export const slackMessageForm = createSlice({
  name: 'slackMessageForm',
  initialState,
  reducers: {
    post: () => ({
      isError: false,
      isLoading: true,
      errorMessages: [],
      posted: false,
    }),
    postSuccess: () => ({
      isError: false,
      isLoading: false,
      errorMessages: [],
      posted: true,
    }),
    postFailed: (_, action: PostSlackMessageFailedAction) => ({
      isError: true,
      isLoading: false,
      errorMessages: action.payload.messages,
      posted: false,
    }),
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(router.actions.pageChange, () => initialState);
  },
});
