import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ShortUrlState = {
  loading: boolean;
  fullUrl?: string;
  errorMessages: string[];
};

const initialState: ShortUrlState = {
  loading: false,
  fullUrl: undefined,
  errorMessages: [],
};

type FetchShortUrlSuccessAction = PayloadAction<{
  fullUrl: string;
}>;

type FetchShortUrlFailedAction = PayloadAction<{
  messages: string[];
}>;

export const shortUrl = createSlice({
  name: 'shortUrl',
  initialState,
  reducers: {
    fetchStart: (state) => ({
      ...state,
      fullUrl: undefined,
      loading: true,
      errorMessages: [],
    }),
    fetchSuccess: (state, action: FetchShortUrlSuccessAction) => ({
      ...state,
      loading: false,
      errorMessages: [],
      fullUrl: action.payload.fullUrl,
    }),
    fetchFailed: (state, action: FetchShortUrlFailedAction) => ({
      ...state,
      loading: false,
      errorMessages: action.payload.messages,
    }),
  },
});
