import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type RouterState = {
  url: string;
};

const initialState: RouterState = {
  url: '',
};

export type RouterUpdateAction = PayloadAction<{
  url: string;
}>;

export type RouterChangeAction = PayloadAction<{
  url: string;
}>;

export const router = createSlice({
  name: 'router',
  initialState,
  reducers: {
    update: (_, action: RouterUpdateAction) => ({
      url: action.payload.url,
    }),
    pageChange: (_, action: RouterChangeAction) => ({
      url: action.payload.url,
    }),
  },
});
