import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type OrderDetailState = {
  isFetching: boolean;
  order?: OrderDetailModel;
  errorMessages: string[];
};

const initialState: OrderDetailState = {
  isFetching: false,
  order: undefined,
  errorMessages: [],
};

type FetchOrderDetailSuccessAction = PayloadAction<{
  order: OrderDetailModel;
}>;

type FetchOrderDetailFailedAction = PayloadAction<{
  messages: string[];
}>;

export const orderDetail = createSlice({
  name: 'orderDetail',
  initialState,
  reducers: {
    fetchOrderDetailStart: (state) => ({
      ...state,
      order: undefined,
      isFetching: true,
      errorMessages: [],
    }),
    fetchOrderDetailSuccess: (
      state,
      action: FetchOrderDetailSuccessAction,
    ) => ({
      ...state,
      isFetching: false,
      errorMessages: [],
      order: action.payload.order,
    }),
    fetchOrderDetailFailed: (state, action: FetchOrderDetailFailedAction) => ({
      ...state,
      isFetching: false,
      errorMessages: action.payload.messages,
    }),
  },
});
