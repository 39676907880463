import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setPasswordForm } from './setPasswordForm';
import { loginForm } from './loginForm';
import { logoutForm } from './logoutForm';

type UserSession = {
  loggedIn: true;
  name: string;
  userId: number;
  serviceType: CompanyServiceType;
};

type AnonymousSession = {
  loggedIn: false;
};

type Session = UserSession | AnonymousSession;

type SessionState = {
  loaded: boolean; // 初回のデータ取得が完了したかどうか
  session: Session;
};

const initialState: SessionState = {
  loaded: false,
  session: { loggedIn: false },
};

type SessionLoadSuccessAction = PayloadAction<{
  name?: string;
  userId?: number;
  serviceType?: CompanyServiceType;
}>;

type SessionLoadFailedAction = PayloadAction<{
  messages: string[];
}>;

export const session = createSlice({
  name: 'session',
  initialState,
  reducers: {
    load: (state) => state,
    loadSuccess: (state, action: SessionLoadSuccessAction) => {
      const { name, userId, serviceType } = action.payload;

      const loggedIn =
        name !== undefined && userId !== undefined && serviceType !== undefined;

      const session = loggedIn
        ? ({ loggedIn, name, userId, serviceType } as UserSession)
        : ({ loggedIn } as AnonymousSession);

      return { ...state, loaded: true, session };
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadFailed: (state, _: SessionLoadFailedAction) => ({
      ...state,
      loaded: true,
      session: { loggedIn: false },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(loginForm.actions.loginSuccess, (_, action) => ({
      loaded: true,
      session: { loggedIn: true, ...action.payload },
    }));
    builder.addCase(setPasswordForm.actions.setSuccess, (_, action) => ({
      loaded: true,
      session: { loggedIn: true, ...action.payload },
    }));
    builder.addCase(logoutForm.actions.logoutSuccess, () => ({
      loaded: true,
      session: { loggedIn: false },
    }));
  },
});
