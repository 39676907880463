import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { router } from './router';

type ResetPasswordRequestFormState = {
  isError: boolean;
  isLoading: boolean;
  errorMessages: string[];
  done: boolean;
};

const initialState: ResetPasswordRequestFormState = {
  isError: false,
  isLoading: false,
  errorMessages: [],
  done: false,
};

type RequestResetPasswordFailedAction = PayloadAction<{
  messages: string[];
}>;

export const resetPasswordRequestForm = createSlice({
  name: 'resetPasswordRequestForm',
  initialState,
  reducers: {
    request: (state) => ({
      ...state,
      isError: false,
      isLoading: true,
      errorMessages: [],
    }),
    requestSuccess: () => ({
      isError: false,
      isLoading: false,
      errorMessages: [],
      done: true,
    }),
    requestFailed: (state, action: RequestResetPasswordFailedAction) => ({
      ...state,
      isError: true,
      isLoading: false,
      errorMessages: action.payload.messages,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(router.actions.pageChange, () => initialState);
  },
});
