import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { router } from './router';

type SetPasswordTokenState = {
  loading: boolean;
  isValid?: boolean;
  errorMessages: string[];
};

const initialState: SetPasswordTokenState = {
  loading: true, // ページrendering時はload状態にする
  errorMessages: [],
};

type VerifySetPasswordTokenFailedAction = PayloadAction<{
  messages: string[];
}>;

export const setPasswordToken = createSlice({
  name: 'setPasswordToken',
  initialState,
  reducers: {
    verify: (state) => ({
      ...state,
      loading: true,
      errorMessages: [],
    }),
    verifySuccess: () => ({
      loading: false,
      isValid: true,
      errorMessages: [],
    }),
    verifyFailed: (state, action: VerifySetPasswordTokenFailedAction) => ({
      ...state,
      loading: false,
      isValid: false,
      errorMessages: action.payload.messages,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(router.actions.pageChange, () => initialState);
  },
});
