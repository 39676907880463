import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ModalState = {
  isOpened: boolean;
  isLocked: boolean;
  modalType: ModalType | null;
};

const initialState: ModalState = {
  isOpened: false,
  isLocked: true,
  modalType: null,
};

type OpenModalAction = PayloadAction<{
  modalType: ModalType;
}>;

export const modal = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    open: (_, action: OpenModalAction) => ({
      isOpened: true,
      isLocked: false,
      modalType: action.payload.modalType,
    }),
    close: () => ({
      isOpened: false,
      isLocked: false,
      modalType: null,
    }),
    lock: (state) => ({
      ...state,
      isLocked: true,
    }),
    unlock: (state) => ({
      ...state,
      isLocked: false,
    }),
  },
});
