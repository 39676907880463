import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { router } from './router';
import { setPasswordForm } from './setPasswordForm';

type LoginFormState = {
  isError: boolean;
  isLoading: boolean;
  errorMessages: string[];
};

const initialState: LoginFormState = {
  isError: false,
  isLoading: false,
  errorMessages: [],
};

type LoginSuccessAction = PayloadAction<{
  name: string;
  userId: number;
  serviceType: CompanyServiceType;
}>;

type LoginFailedAction = PayloadAction<{
  messages: string[];
}>;

export const loginForm = createSlice({
  name: 'loginForm',
  initialState,
  reducers: {
    login: () => ({
      isError: false,
      isLoading: true,
      errorMessages: [],
    }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loginSuccess: (_, __: LoginSuccessAction) => initialState,
    loginFailed: (_, action: LoginFailedAction) => ({
      isError: true,
      isLoading: false,
      errorMessages: action.payload.messages,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(setPasswordForm.actions.setSuccess, () => initialState);
    builder.addCase(router.actions.pageChange, () => initialState);
  },
});
