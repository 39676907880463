import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { router } from './router';

type SetPasswordFormState = {
  isLoading: boolean;
  isError: boolean;
  errorMessages: string[];
  done: boolean;
};

const initialState: SetPasswordFormState = {
  isLoading: false,
  isError: false,
  errorMessages: [],
  done: false,
};

type SetPasswordSuccessAction = PayloadAction<{
  name: string;
  userId: number;
  serviceType: CompanyServiceType;
}>;

type SetPasswordFailedAction = PayloadAction<{
  messages: string[];
}>;

export const setPasswordForm = createSlice({
  name: 'setPasswordForm',
  initialState,
  reducers: {
    set: (state) => ({
      ...state,
      isLoading: true,
      isError: false,
      errorMessages: [],
    }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setSuccess: (_, __: SetPasswordSuccessAction) => ({
      isLoading: false,
      isError: false,
      errorMessages: [],
      done: true,
    }),
    setFailed: (state, action: SetPasswordFailedAction) => ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessages: action.payload.messages,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(router.actions.pageChange, () => initialState);
  },
});
