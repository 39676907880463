import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { router } from './router';
import { slackMessageForm } from './slackMessageForm';

type SlacAppInstallationState = {
  isError: boolean;
  isLoading: boolean;
  errorMessages: string[];
};

const initialState: SlacAppInstallationState = {
  isError: false,
  isLoading: false,
  errorMessages: [],
};

type InstallSlackAppFailedAction = PayloadAction<{
  messages: string[];
}>;

export const slackAppInstallation = createSlice({
  name: 'slackAppInstallation',
  initialState,
  reducers: {
    install: () => ({
      isError: false,
      isLoading: true,
      errorMessages: [],
    }),
    installSuccess: () => ({
      isError: false,
      isLoading: false,
      errorMessages: [],
    }),
    installFailed: (_, action: InstallSlackAppFailedAction) => ({
      isError: true,
      isLoading: false,
      errorMessages: action.payload.messages,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(slackMessageForm.actions.postSuccess, () => ({
      isError: false,
      isLoading: false,
      errorMessages: [],
    }));
    builder.addCase(router.actions.pageChange, (state, action) => {
      // OAuth callbackからSlack連携ページへ遷移する際にエラーが消えないようにする
      if (action.payload.url === '/config/slack') {
        return state;
      }

      return initialState;
    });
  },
});
