import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { router } from './router';

type LogoutFormState = {
  isLoading: boolean;
  errorMessages: string[];
};

const initialState: LogoutFormState = {
  isLoading: false,
  errorMessages: [],
};

type LogoutFailedAction = PayloadAction<{
  messages: string[];
}>;

export const logoutForm = createSlice({
  name: 'logoutForm',
  initialState,
  reducers: {
    logout: () => ({
      isLoading: true,
      errorMessages: [],
    }),
    logoutSuccess: () => initialState,
    logoutFailed: (_, action: LogoutFailedAction) => ({
      isLoading: false,
      errorMessages: action.payload.messages,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(router.actions.pageChange, () => initialState);
  },
});
